import React from 'react';
import { Layout } from '../components/layout';


const NotFoundPage = () => (
  <Layout title={"Page non trouvée"} isVisible={false}>
    <h1>Oups la page n'est pas trouvée...</h1>
  </Layout>
)

export default NotFoundPage